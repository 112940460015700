import React, { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { Container, Row } from "react-bootstrap";
import british from "../assets/image/png/british.png";
import kasada from "../assets/image/png/kasada.png";
import tpg from "../assets/image/png/tpg.png";
import rise from "../assets/image/png/rise.png";
import mcom from "../assets/image/png/mcom.png";
import actis from "../assets/image/png/actis.png";
import evercare from "../assets/image/png/evercare.png";
import star from "../assets/image/png/star.png";
import afc from "../assets/image/png/afc.jpg";
import Capti from "../assets/image/svg/capti.svg";
const Trust = () => {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".trust_logos",
          start: "top 40%",
          // markers: true,
        },
      })
      .fromTo(
        ".brands_hover",
        {
          scale: 0.5,
          // translateX: "-60px",
        },
        {
          scale: 1,
          // translateX: "0px",
          // visibility: "visible",
          autoAlpha: 1,
          stagger: 0.1,
          ease: "linear",
        },
        "-=0.1"
      );
  }, []);
  return (
    <>
      <section className="py-md-5 position-relative trust_logos">
        {/* <img className="position-abso " src={star} alt="Victor" /> */}
        <Container>
          <p className="ff_karla fw_700 fs_20 mb-0 text-center">Trusted by</p>

          <div className="row align-items-center justify-content-lg-start justify-content-center justify-content-lg-between  pt-3">
            
            <div className="my-col">
              <img className="brands_hover w-100" src={actis} alt="actis" />
            </div>
            <div className="my-col mt-4 mt-md-0">
              <img className="brands_hover w-100" src={afc} alt="afc" />
            </div>
            <div className="my-col">
              <img className="brands_hover w-100" src={british} alt="british" />
            </div>
            <div className="my-col">
              <img className="brands_hover w-100" src={Capti} alt="british" />
            </div>
            <div className="my-col">
              <img
                className="brands_hover w-100"
                src={evercare}
                alt=" evercare"
              />
            </div>
            <div className="my-col mt-4 mt-md-0">
              <img className="brands_hover w-100" src={kasada} alt="kasada" />
            </div>
            {/* <div className="my-col mt-4 mt-md-0">
              <img className="brands_hover w-100" src={mcom} alt=" mcom" />
            </div> */}
            <div className="my-col mt-4 mt-md-0">
              <img className="brands_hover w-100" src={rise} alt="rise" />
            </div>
            <div className="my-col mt-4 mt-md-0">
              <img className="brands_hover w-100" src={tpg} alt="tpg" />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Trust;
