import React, { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { Container } from "react-bootstrap";
import image_1 from "../assets/image/png/image_1.png";
import image_2 from "../assets/image/png/image_2.png";
import image_3 from "../assets/image/png/image_3.png";
import Carousel from "react-bootstrap/Carousel";
import Victor from "../assets/image/png/Vector.png";

function Service() {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".services",
          start: "top 90%",
          // markers: true,
        },
      })
      .fromTo(
        ".box",
        {
          scale: 0.5,
          // translateX: "-60px",
        },
        {
          scale: 1,
          // translateX: "0px",
          // visibility: "visible",
          ease: "linear",
        }
      );
  }, []);

  return (
    <>
      <section className="py-md-5 pt-3 pb-5 services  position-relative overflow-hidden">
        {/* <img
          className="position-absolute animation_translate top-0 start-0 "
          src={Victor}
          alt="Victor"
        /> */}
        <Container>
          <div className="row d-sm-flex d-none justify-content-center">
            <div className="col-md-4 col-sm-6 mt-3 mt-sm-0">
              <div className="box">
                <div className="overflow-hidden rounded">
                  <img className="w-100 img" src={image_1} alt="image_1" />
                </div>
                <h3 className="fs_20 fw_700 ff_karla text-center mb-0 pb-2 pt-3">
                Renewable and Clean Energy
                </h3>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mt-3 mt-sm-0">
              <div className="box">
                <div className="overflow-hidden rounded">
                  <img className="w-100 img" src={image_2} alt="image_1" />
                </div>
                <h3 className="fs_20 fw_700 ff_karla text-center mb-0 pb-2 pt-3">
                  Digital Infrastructure
                </h3>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mt-3 mt-md-0">
              <div className="box">
                <div className="overflow-hidden rounded">
                  <img className="w-100 img" src={image_3} alt="image_1" />
                </div>
                <h3 className="fs_20 fw_700 ff_karla text-center mb-0 pb-2 pt-3">
                  Industrial | Logistics
                </h3>
              </div>
            </div>
          </div>
          <Carousel className="d-sm-none">
            <Carousel.Item>
              <div className="col-md-4 col-sm-6 mt-3 mt-sm-0">
                <div className="box">
                  <img className="w-100" src={image_1} alt="image_1" />
                  <h3 className="fs_20 fw_700 ff_karla text-center mb-0 pb-2 pt-3">
                    Cleantech
                  </h3>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="col-md-4 col-sm-6 mt-3 mt-sm-0">
                <div className="box">
                  <img className="w-100" src={image_2} alt="image_1" />
                  <h3 className="fs_20 fw_700 ff_karla text-center mb-0 pb-2 pt-3">
                    Digital Infrastructure
                  </h3>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="col-md-4 col-sm-6 mt-3 mt-md-0">
                <div className="box">
                  <img className="w-100" src={image_3} alt="image_1" />
                  <h3 className="fs_20 fw_700 ff_karla text-center mb-0 pb-2 pt-3">
                    Digital Infrastructure
                  </h3>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </Container>
      </section>
    </>
  );
}

export default Service;
